.header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 3;
  }
  
  .hover-link:hover{
    background : #EDF2F7 !important
  }
  
  .center {
    display: flex;
    justify-content: center;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 10vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .full-page {
    background-color: #D1D9F5;
    height: full;
  }
  
  .header {
    background-color: white;
  }
  
  .App-header, .form-body {
    background-color: #D1D9F5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: black;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .regular-font-style {
    font-family: "Pangram";
    color: #B8435B;
    font-size: large;
  }

  .medium-font-style {
    font-family: "Pangram Medium";
    color: #B8435B;
    margin-bottom: 3pt;
    font-size: large;
  }

  .medium-black-font-style {
    font-family: "Pangram Medium";
    margin-bottom: 3pt;
    font-size: large;
    color: #74777b;
  }

  .title-font {
    font-family: "Pangram Bold";
    color: #B8435B;
  }

  .label-font {
    position: relative;
    font-family: "Pangram";
    font-size: 1.1em;
    display: inline-block;
    margin-top: 1em;
    color: red;
  }