body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Pangram', 'Pangram Bold', 'Pangram-Medium'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Pangram";
  src: local("Pangram"),
   url("fonts/Pangram-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pangram Bold";
  src: local("Pangram-Bold"),
   url("fonts/Pangram-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Pangram Medium";
  src: local("Pangram-Medium"),
   url("fonts/Pangram-Medium.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
